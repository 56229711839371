import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import 'intersection-observer';
import Observer from '@researchgate/react-intersection-observer';
import TransitionLink from 'gatsby-plugin-transition-link';
import gsap, { Expo } from 'gsap';
import Layout from '../../components/layout.js';
import Transition from '../../components/transition.js';
import Header from '../../components/header.js';
import Footer from '../../components/footer.js';
import { InitTransition } from '../../components/init-transition.js';
import InitAnimation from '../../components/init-animation.js';
import Style from '../../styles/works/work.module.scss';

// Main Visual
import MainVisualSp from '../../images/works/konan-one-day/main_visual_sp.jpg';
import MainVisualPc from '../../images/works/konan-one-day/main_visual_pc.jpg';
// OtherWorks
import Link01 from '../../components/published-seesaw.js';
// import Link02 from '../../components/published-.js';

class KonanOneDay extends React.Component {
  constructor() {
    super();
    this.child = React.createRef();
    this.initAnimation = new InitAnimation();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const img = document.querySelectorAll('.init_a_img');
    setTimeout(() => {
      gsap.to(img, {
        duration: 1.5,
        scale: 1,
        ease: Expo.easeOut,
      });
    }, 1000);
    const header = document.querySelector('header');
    const visual = document.querySelector('.visual');
    const visualHeight = visual.clientHeight - 64;
    window.addEventListener('scroll', () => { this.changeHeader(header, visualHeight); });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', () => { this.changeHeader(); });
  }

  changeHeader = (e, height) => {
    if (window.pageYOffset > height) {
      e.classList.add('work');
    } else {
      e.classList.remove('work');
    }
  };

  details = ({ isIntersecting }, unobserve) => {
    if (isIntersecting) {
      const detailsItem = document.querySelectorAll('.init_a_detailsItem');
      this.initAnimation.list(detailsItem);
      unobserve();
    }
  };

  challengesPoint = ({ isIntersecting }, unobserve) => {
    if (isIntersecting) {
      const isChallengesPoint = 'ChallengesPoint';
      const headingChallengesPoint = document.querySelector('.init_a_headingChallengesPoint');
      const challengesPointItem = document.querySelectorAll('.init_a_challengesPointItem');
      this.initAnimation.heading(headingChallengesPoint, isChallengesPoint);
      this.initAnimation.list(challengesPointItem);
      unobserve();
    }
  };

  subCut1 = ({ isIntersecting }, unobserve) => {
    if (isIntersecting) {
      const subCutBg = document.querySelector('.init_a_subCut1_bg');
      const subCutImg = document.querySelector('.init_a_subCut1_img');
      this.initAnimation.slider(subCutBg, subCutImg);
      unobserve();
    }
  };

  subCut2 = ({ isIntersecting }, unobserve) => {
    if (isIntersecting) {
      const subCutBg = document.querySelector('.init_a_subCut2_bg');
      const subCutImg = document.querySelector('.init_a_subCut2_img');
      this.initAnimation.slider(subCutBg, subCutImg);
      unobserve();
    }
  };

  subCut3 = ({ isIntersecting }, unobserve) => {
    if (isIntersecting) {
      const subCutBg = document.querySelector('.init_a_subCut3_bg');
      const subCutImg = document.querySelector('.init_a_subCut3_img');
      this.initAnimation.slider(subCutBg, subCutImg);
      unobserve();
    }
  };

  subCut4 = ({ isIntersecting }, unobserve) => {
    if (isIntersecting) {
      const subCutBg = document.querySelector('.init_a_subCut4_bg');
      const subCutImg = document.querySelector('.init_a_subCut4_img');
      this.initAnimation.slider(subCutBg, subCutImg);
      unobserve();
    }
  };

  subCut5 = ({ isIntersecting }, unobserve) => {
    if (isIntersecting) {
      const subCutBg = document.querySelector('.init_a_subCut5_bg');
      const subCutImg = document.querySelector('.init_a_subCut5_img');
      this.initAnimation.slider(subCutBg, subCutImg);
      unobserve();
    }
  };

  approach = ({ isIntersecting }, unobserve) => {
    if (isIntersecting) {
      const isApproach = 'Approach';
      const headingApproach = document.querySelector('.init_a_headingApproach');
      const approachItem = document.querySelectorAll('.init_a_approachItem');
      this.initAnimation.heading(headingApproach, isApproach);
      this.initAnimation.list(approachItem);
      unobserve();
    }
  };

  projectTeam01 = ({ isIntersecting }, unobserve) => {
    if (isIntersecting) {
      const isProjectTeam = 'ProjectTeam';
      const headingProjectTeam = document.querySelector('.init_a_headingProjectTeam');
      const projectTeamItem01 = document.querySelectorAll('.init_a_projectTeamItem01');
      this.initAnimation.heading(headingProjectTeam, isProjectTeam);
      this.initAnimation.list(projectTeamItem01);
      unobserve();
    }
  };

  projectTeam02 = ({ isIntersecting }, unobserve) => {
    if (isIntersecting) {
      const projectTeamItem02 = document.querySelectorAll('.init_a_projectTeamItem02');
      this.initAnimation.list(projectTeamItem02);
      unobserve();
    }
  };

  otherWorks = ({ isIntersecting }, unobserve) => {
    if (isIntersecting) {
      const isOtherWorks = 'OtherWorks';
      const headingOtherWorks = document.querySelector('.init_a_headingOtherWorks');
      this.initAnimation.heading(headingOtherWorks, isOtherWorks);
      unobserve();
    }
  };

  render() {
    const WorkQuery = graphql`
      {
        DeviceSp: file(relativePath: { eq: "works/konan-one-day/device_sp.png" }){
          childImageSharp {
            fluid(maxWidth: 170, quality: 70) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        },
        DevicePc: file(relativePath: { eq: "works/konan-one-day/device_pc.png" }){
          childImageSharp {
            fluid(maxWidth: 715, quality: 70) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        },
        subCut1: file(relativePath: { eq: "works/konan-one-day/subcut_1.png" }){
          childImageSharp {
            fluid(maxWidth: 960, quality: 90) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        },
        subCut2: file(relativePath: { eq: "works/konan-one-day/subcut_2.png" }){
          childImageSharp {
            fluid(maxWidth: 960, quality: 90) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        },
        subCut3: file(relativePath: { eq: "works/konan-one-day/subcut_3.png" }){
          childImageSharp {
            fluid(maxWidth: 960, quality: 90) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        },
        subCut4: file(relativePath: { eq: "works/konan-one-day/subcut_4.png" }){
          childImageSharp {
            fluid(maxWidth: 960, quality: 90) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        },
        subCut5: file(relativePath: { eq: "works/konan-one-day/subcut_5.png" }){
          childImageSharp {
            fluid(maxWidth: 960, quality: 90) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        },
      }
    `;

    const transition = {
      InitTransition,
      animation: () => {
        this.child.current.exitAnimation(transition.InitTransition.length);
      },
    };

    const optionsDetails = {
      root: null,
      rootMargin: '-50% 0px',
      threshold: 0,
    };

    const options = {
      root: null,
      rootMargin: '-50px',
      threshold: 0,
    };

    return (
      <StaticQuery
        query={WorkQuery}
        render={(data) => (
          <Layout>
            <Transition ref={this.child}>
              <Header isWork transition={transition} current="works" />
              <main className={`work ${Style.item}`}>
                <div className={`visual ${Style.visual}`}>
                  <figure className={`init_a_visual ${Style.visualFigure}`}>
                    <img
                      className="spTabVDisBlock init_a_img"
                      src={MainVisualSp}
                      style={{filter: 'brightness(90%) contrast(90%)'}}
                      alt="ある日、ひとつのキャンパスで、"
                    />
                    <img
                      className="tabHPcDisBlock init_a_img"
                      src={MainVisualPc}
                      style={{filter: 'brightness(90%) contrast(90%)'}}
                      alt="ある日、ひとつのキャンパスで、"
                    />
                  </figure>
                  <div className={Style.visualDevice}>
                    <Img
                      className="spTabVDisBlock"
                      fluid={data.DeviceSp.childImageSharp.fluid}
                      alt="ある日、ひとつのキャンパスで、"
                    />
                    <Img
                      className="tabHPcDisBlock"
                      fluid={data.DevicePc.childImageSharp.fluid}
                      alt="ある日、ひとつのキャンパスで、"
                    />
                  </div>
                </div>
                <Observer {...optionsDetails} onChange={this.details}>
                  <section className={`details ${Style.details}`}>
                    <div className={`inner ${Style.inner}`}>
                      <div className={Style.block}>
                        <div className={Style.left}>
                          <p className={`init_a_detailsCategory_text ${Style.detailsCategory}`}>ウェブサイト構築</p>
                          <h1 className={`init_a_detailsTitle_text ${Style.detailsTitle}`}>
                          ある日、ひとつのキャンパスで、
                          </h1>
                          <p className={`init_a_detailsCaption_text ${Style.detailsCaption}`}>甲南女子大学</p>

                          <p className={`init_a_detailsItem ${Style.description}`}>甲南女子大学の施設と大学生活を紹介する特設サイトを制作しました。サイトのコンセプト設計から、コピーライティング、アートディレクションなどを担当しています。撮影は若手写真家の柘植美咲さんにご協力いただきました。</p>
                          <p className={`init_a_detailsItem ${Style.link}`}>
                            <a
                              href="https://www.konan-wu.ac.jp/one-day/"
                              target="_blank"
                            >
                              https://www.konan-wu.ac.jp/one-day/
                              <svg
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                viewBox="0 0 17 14"
                              >
                                <rect x="4.5" y="0.5" width="12" height="9" />
                                <path d="M16,1v8H5V1H16 M17,0H4v10h13V0L17,0z" />
                                <rect x="0.5" y="4.5" width="12" height="9" />
                                <path d="M12,5v8H1V5H12 M13,4H0v10h13V4L13,4z" />
                              </svg>
                            </a>
                          </p>
                        </div>
                        <div className={Style.info}>
                          <dl className="init_a_detailsItem">
                            <dt>メディア</dt>
                            <dd>特設サイト</dd>
                          </dl>
                          <dl className="init_a_detailsItem">
                            <dt>プロジェクト期間</dt>
                            <dd>2022年4月〜9月</dd>
                          </dl>
                          <dl className="init_a_detailsItem">
                            <dt>ソリューション</dt>
                            <dd>コンセプト設計／コンテンツ企画／情報設計／アートディレクション／撮影ディレクション／コピーライティング</dd>
                          </dl>
                        </div>
                      </div>
                    </div>
                  </section>
                </Observer>
                <section className="sectionContent">
                  <div className="inner">
                    <Observer {...options} onChange={this.challengesPoint}>
                      <div>
                        <h2 className="heading">
                          <span className="text textChallengesPoint">Overview</span>
                          <span className="clip clipChallengesPoint init_a_headingChallengesPoint">Overview</span>
                        </h2>
                        <p className="init_a_challengesPointItem sectionAddition">概要</p>
                        <div className={`init_a_challengesPointItem ${Style.text}`}>
                          <p>「甲南女子大学」は兵庫県神戸市にキャンパスを置く、私立の女子大学です。</p>
                          <p>オープンキャンパスの来場者アンケートで「“意外と”キャンパスが綺麗だった」という声が多く挙がっており、現状の大学公式サイトや大学案内だけでは、甲南女子大学の強みでもある施設の魅力がうまく発信できていないという課題がありました。その点をカバーするとともに、甲南女子大学のことを知りはじめた（たまたま知った）層に、ここでなら素敵な大学生活が送れそう、と憧れを抱いてもらうことを目的としました。</p>
                        </div>
                      </div>
                    </Observer>
                    <Observer {...options} onChange={this.subCut1}>
                      <figure className={`init_a_subCut ${Style.slider}`}>
                        <div className="init_a_subCut_bg init_a_subCut1_bg" />
                        <Img
                          className="init_a_subCut_img init_a_subCut1_img"
                          fluid={data.subCut1.childImageSharp.fluid}
                          alt="ある日、ひとつのキャンパスで、"
                        />
                      </figure>
                    </Observer>
                    <Observer {...options} onChange={this.subCut2}>
                      <figure className={`init_a_subCut ${Style.slider}`}>
                        <div className="init_a_subCut_bg init_a_subCut2_bg" />
                        <Img
                          className="init_a_subCut_img init_a_subCut2_img"
                          fluid={data.subCut2.childImageSharp.fluid}
                          alt="ある日、ひとつのキャンパスで、"
                        />
                      </figure>
                    </Observer>
                    <Observer {...options} onChange={this.subCut3}>
                      <figure className={`init_a_subCut ${Style.slider}`}>
                        <div className="init_a_subCut_bg init_a_subCut3_bg" />
                        <Img
                          className="init_a_subCut_img init_a_subCut3_img"
                          fluid={data.subCut3.childImageSharp.fluid}
                          alt="ある日、ひとつのキャンパスで、"
                        />
                      </figure>
                    </Observer>
                    <Observer {...options} onChange={this.subCut4}>
                      <figure className={`init_a_subCut ${Style.slider}`}>
                        <div className="init_a_subCut_bg init_a_subCut4_bg" />
                        <Img
                          className="init_a_subCut_img init_a_subCut4_img"
                          fluid={data.subCut4.childImageSharp.fluid}
                          alt="ある日、ひとつのキャンパスで、"
                        />
                      </figure>
                    </Observer>
                    <Observer {...options} onChange={this.subCut5}>
                      <figure className={`init_a_subCut ${Style.slider}`}>
                        <div className="init_a_subCut_bg init_a_subCut5_bg" />
                        <Img
                          className="init_a_subCut_img init_a_subCut5_img"
                          fluid={data.subCut5.childImageSharp.fluid}
                          alt="ある日、ひとつのキャンパスで、"
                        />
                      </figure>
                    </Observer>
                  </div>
                </section>

                <Observer {...options} onChange={this.approach}>
                  <section className="sectionContent">
                    <div className="inner">
                      <h2 className="heading">
                        <span className="text textApproach">Approach</span>
                        <span className="clip clipApproach init_a_headingApproach">Approach</span>
                      </h2>
                      <p className="init_a_approachItem sectionAddition">アプローチ</p>
                      <div className={`init_a_approachItem ${Style.text}`}>
                        <p>「大学を舞台にした映画のスチール」をイメージして、大学の1日の姿を写真で追うようなサイト構成にしました。より感覚的に興味を持ってもらえるよう、“情報”の部分は下層ページに集約させ、トップページは写真とキャッチコピーのみというシンプルな構造にしています。</p>
                        <p>制作の肝となる写真撮影は「その場の空気感」を捉えるのが得意かつ、高校生や大学生とも年齢が近い、若手写真家の柘植美咲さんにご協力いただきました。<br />その場にいる学生や職員の姿も写り込むようにして、単なる施設紹介にとどまらず、ここでの大学生活を自分ごととしてリアルに想像してもらえるように意識しています。</p>
                      </div>
                    </div>
                  </section>
                </Observer>
                <section className={`sectionContent ${Style.projectTeam}`}>
                  <div className={`inner ${Style.inner}`}>
                    <Observer {...options} onChange={this.projectTeam01}>
                      <div>
                        <h2 className="heading">
                          <span className="text textProjectTeam">Project <br className="spDisBlock" />team</span>
                          <span className="clip clipProjectTeam init_a_headingProjectTeam">Project <br className="spDisBlock" />team</span>
                        </h2>
                        <p className="init_a_projectTeamItem01 sectionAddition">プロジェクトチームのご紹介</p>
                        <p className={`init_a_projectTeamItem01 ${Style.intro}`}>
                          競争から共創へ。
                          <br />
                          弊社では、クライアントと
                          <br className="spDisBlock" />
                          受託会社、制作会社と
                          <br />
                          外部パートナーといった
                          <br className="spDisBlock" />
                          垣根を越え、
                          <br />
                          プロジェクトごとに最適な
                          <br className="spDisBlock" />
                          チームを編成しています。
                        </p>
                      </div>
                    </Observer>
                    <Observer {...options} onChange={this.projectTeam02}>
                      <div>
                        <div className={Style.credit}>
                          <h3 className="init_a_projectTeamItem02">プロジェクト体制</h3>
                          <div className="init_a_projectTeamItem02">
                            <dl>
                              <dt>長谷川 茉由・松田 寛志</dt>
                              <dd>Strategy／Contents</dd>
                            </dl>
                          </div>
                        </div>
                        <div className={Style.credit}>
                          <h3 className="init_a_projectTeamItem02">外部パートナー</h3>
                          <dl className="init_a_projectTeamItem02">
                            <dt>株式会社進研アド</dt>
                            <dt>飯田 久美子</dt>
                            <dt>柘植 美咲</dt>
                          </dl>
                        </div>
                        <div className={Style.credit}>
                          <h3 className="init_a_projectTeamItem02">クライアント</h3>
                          <p className={`init_a_projectTeamItem02 ${Style.client}`}>甲南女子大学</p>
                        </div>
                      </div>
                    </Observer>
                  </div>
                </section>
                <Observer {...options} onChange={this.otherWorks}>
                  <section className={`sectionContent ${Style.otherWorks}`}>
                    <h2 className="heading">
                      <span className="text textOtherWorks">Other <br className="spDisBlock" />works</span>
                      <span className="clip clipOtherWorks init_a_headingOtherWorks">Other <br className="spDisBlock" />works</span>
                    </h2>

                    <Link01 transition={transition} />
                    {/* <Link02 transition={transition} /> */}

                    <p className={`btn ${Style.btn}`}>
                      <TransitionLink to="/works" exit={{ length: transition.InitTransition.length, trigger: () => transition.animation() }} entry={{ delay: transition.InitTransition.delay }}>
                        <span>Explore our works</span>
                      </TransitionLink>
                    </p>
                  </section>
                </Observer>
              </main>
              <Footer transition={transition} />
            </Transition>
          </Layout>
        )}
      />
    );
  }
}

export default KonanOneDay;
